import React, { useEffect, useMemo, useState } from "react";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { fromJS, List } from "immutable";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableCell, withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Modal from "@material-ui/core/Modal";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { apiHOCs, forms } from "../../components";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#E1E1E1",
      transition: "background .4s ease",
      cursor: "pointer",
    },
  },
}))(TableCell);

const useStyles = makeStyles({
  modalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 440,
    height: 150,
    overflow: "auto",
    border: 0,
    "&:focus": {
      outline: "none",
    },
  },
});

const DealTimers = ({
  getDealTimers,
  putDealTimer,
  dealTimers,
  updateDealTimer,
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [selectedTimerInfo, setSelectedTimerInfo] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [timers, setTimers] = useState(List());

  useEffect(() => {
    getDealTimers((response) => {
      setTimers(fromJS(response.data));
    });
  }, [getDealTimers]);

  const [dealFlows, rowsByDealStatus] = useMemo(() => {
    if (timers.size) {
      const rowsByDealStatus = new Map();
      const dealFlows = new Set();
      timers.forEach((timer) => {
        const flow = timer.get("deal_flow");
        dealFlows.add(flow);
        const status = timer.get("deal_status");
        let flowsForStatus = rowsByDealStatus.get(status);
        if (flowsForStatus === undefined) {
          flowsForStatus = new Map();
          flowsForStatus.set(flow, timer);
          rowsByDealStatus.set(status, flowsForStatus);
        } else {
          flowsForStatus.set(flow, timer);
        }
      });

      return [dealFlows, rowsByDealStatus];
    }

    return [new Set(), new Map()];
  }, [timers]);

  const handlePressTimer = ({ initialValue, alias }) => {
    setSelectedTimerInfo({ initialValue, alias });
    setModal(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Table
          className={classes.table}
          size="medium"
          aria-label="a dense table"
        >
          <TableHead color="dark">
            <TableRow>
              <StyledTableCell>Status</StyledTableCell>
              {[...dealFlows].map((flowType) => (
                <StyledTableCell key={flowType} align="right">
                  {`${flowType} (min)`}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(rowsByDealStatus).map(([status, rows]) => (
              <TableRow key={status}>
                <TableCell component="th" scope="row">
                  {status}
                </TableCell>
                {[...dealFlows].map((flowType) => {
                  const timer = rows.get(flowType);
                  return timer ? (
                    <StyledTableCell
                      onClick={() => {
                        handlePressTimer({
                          initialValue: timer.get("duration"),
                          alias: timer.get("alias"),
                        });
                      }}
                      align="right"
                    >
                      {timer.get("duration")}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell></StyledTableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <Paper elevation={1} className={classes.modalWrapper}>
          <forms.TimerForm
            onSubmit={(body) => {
              putDealTimer({
                alias: selectedTimerInfo.alias,
                body: { duration: parseInt(body.get("minutes"), 10) },
                successCallback: () => {
                  getDealTimers((response) => {
                    const timersData = response.data;
                    setTimers(fromJS(timersData));

                    updateDealTimer(fromJS(timersData));
                    setSnackBarOpen(true);
                    setModal(false);
                  });
                },
              });
            }}
            initialValues={{
              minutes: selectedTimerInfo.initialValue,
            }}
          />
        </Paper>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        open={snackBarOpen}
        onClose={() => {
          setSnackBarOpen(false);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => {
            setSnackBarOpen(false);
          }}
        >
          Таймер обновлен
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default compose(apiHOCs.DealApiHOC())(DealTimers);
